import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title=""
        description=""
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="bg-primary-50 pt-20 pb-28 md:pt-32 md:pb-48">
        <div className="container">
          <header className="mb-12 text-center md:mb-16">
            <h1>Make a Payment</h1>
          </header>
          <div className="mx-auto grid max-w-[920px] gap-10 md:grid-cols-2">
            <div className="rounded-3xl bg-white py-9 px-10 text-center shadow-4xl">
              <div className="heading-five mb-6">
                I’m Making My First Payment
              </div>
              <p>
                For questions regarding your first payment due or if you have
                not received a payment coupon, please contact our team by phone
                or email.
              </p>
              <div className="mb-6">
                <ButtonSolid
                  href="mailto:accounting@rightstartmortgage.com"
                  outboundLink={true}
                  text="Message Us"
                  altStyle={2}
                />
              </div>
              <a
                href="tel:800-520-5626"
                className="flex justify-center space-x-2 text-sm font-semibold text-secondary-500 no-underline hover:text-secondary-500"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.45612 11.4668C9.61584 12.6432 11.0191 13.5515 12.5672 14.1279L14.6617 12.4612C14.7238 12.4184 14.7974 12.3956 14.8728 12.3956C14.9482 12.3956 15.0218 12.4184 15.0839 12.4612L18.9728 14.9668C19.1204 15.0555 19.2454 15.1774 19.3378 15.3228C19.4303 15.4681 19.4877 15.633 19.5055 15.8043C19.5232 15.9757 19.5009 16.1488 19.4403 16.3101C19.3797 16.4713 19.2824 16.6162 19.1561 16.7334L17.3339 18.5334C17.0729 18.7913 16.7522 18.9805 16.4003 19.0842C16.0484 19.188 15.6763 19.203 15.3172 19.1279C11.7351 18.3881 8.43342 16.6554 5.78946 14.1279C3.20417 11.5752 1.41516 8.32724 0.639456 4.77788C0.562826 4.42434 0.578819 4.05701 0.685887 3.71147C0.792954 3.36594 0.987464 3.05392 1.25057 2.80566L3.13946 0.983433C3.25634 0.863315 3.39889 0.771196 3.55643 0.713976C3.71396 0.656755 3.8824 0.635915 4.04913 0.653015C4.21586 0.670116 4.37656 0.724714 4.5192 0.812722C4.66184 0.900729 4.78272 1.01987 4.87279 1.16121L7.46168 5.0001C7.50633 5.06038 7.53044 5.13341 7.53044 5.20843C7.53044 5.28345 7.50633 5.35649 7.46168 5.41677L5.75612 7.46677C6.34811 8.98396 7.27042 10.3503 8.45612 11.4668Z"
                    className="fill-current text-secondary-500"
                  />
                </svg>
                <span>(800) 520-5626</span>
              </a>
            </div>

            <div className="rounded-3xl bg-white py-9 px-10 text-center shadow-4xl">
              <div className="heading-five mb-6">I’m a Returning Customer</div>
              <p>
                Looking for payoff information, payment or tax or hazard account
                information? Log in below to access your account.
              </p>
              <div className="mb-6">
                <ButtonSolid
                  href="https://www.yourmortgageonline.com/"
                  outboundLink={true}
                  text="Service Portal"
                />
              </div>
              <a
                href="tel:877-865-9787"
                className="flex justify-center space-x-2 text-sm font-semibold text-primary-500 no-underline hover:text-primary-500"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.45612 11.4668C9.61584 12.6432 11.0191 13.5515 12.5672 14.1279L14.6617 12.4612C14.7238 12.4184 14.7974 12.3956 14.8728 12.3956C14.9482 12.3956 15.0218 12.4184 15.0839 12.4612L18.9728 14.9668C19.1204 15.0555 19.2454 15.1774 19.3378 15.3228C19.4303 15.4681 19.4877 15.633 19.5055 15.8043C19.5232 15.9757 19.5009 16.1488 19.4403 16.3101C19.3797 16.4713 19.2824 16.6162 19.1561 16.7334L17.3339 18.5334C17.0729 18.7913 16.7522 18.9805 16.4003 19.0842C16.0484 19.188 15.6763 19.203 15.3172 19.1279C11.7351 18.3881 8.43342 16.6554 5.78946 14.1279C3.20417 11.5752 1.41516 8.32724 0.639456 4.77788C0.562826 4.42434 0.578819 4.05701 0.685887 3.71147C0.792954 3.36594 0.987464 3.05392 1.25057 2.80566L3.13946 0.983433C3.25634 0.863315 3.39889 0.771196 3.55643 0.713976C3.71396 0.656755 3.8824 0.635915 4.04913 0.653015C4.21586 0.670116 4.37656 0.724714 4.5192 0.812722C4.66184 0.900729 4.78272 1.01987 4.87279 1.16121L7.46168 5.0001C7.50633 5.06038 7.53044 5.13341 7.53044 5.20843C7.53044 5.28345 7.50633 5.35649 7.46168 5.41677L5.75612 7.46677C6.34811 8.98396 7.27042 10.3503 8.45612 11.4668Z"
                    className="fill-current text-primary-500"
                  />
                </svg>
                <span>(877) 865-9787</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Mortgage Calculators.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Mortgage Calculators.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
